:root {
  --animation-speed: 0.5s;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "ui-sans-serif", "system-ui", "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica Neue", "Arial", "Noto Sans", "sans-serif", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #FFFFFF;
}

a {
  text-decoration: none;
}

a::-moz-selection, p::-moz-selection, h1::-moz-selection, h2::-moz-selection, h3::-moz-selection, h4::-moz-selection, h5::-moz-selection, h6::-moz-selection, span::-moz-selection {
  background-color: #27272D;
}

a::selection, p::selection, h1::selection, h2::selection, h3::selection, h4::selection, h5::selection, h6::selection, span::selection {
  background-color: #27272D;
}

p, h1, h2, h3, h4, h5, h6, span {
  cursor: default;
}

div::-moz-selection, img::-moz-selection {
  background-color: transparent;
}

div::selection, img::selection {
  background-color: transparent;
}

html {
  position: relative;
  background-color: #15151B;
  overflow-x: hidden;
  scroll-behavior: smooth;
  width: 100vw;
  height: 100vh;
}

body {
  margin: 20px clamp(1.875rem, -1.134rem + 15.04vw, 12.5rem);
  position: relative;
}