:root {
  --animation-speed: 0.5s;
}

.header {
  padding: 15px;
  margin-bottom: clamp(3.75rem, 3.219rem + 2.65vw, 5.625rem);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header__logo a {
  display: flex;
  align-items: center;
  gap: 10px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 1.5rem;
  font-weight: 800;
}
@media (max-width: 500px) {
  .header__logo a {
    font-size: 0px;
  }
}
.header__logo img {
  width: 96px;
}
.header-navbar {
  display: flex;
  align-items: center;
  gap: 20px;
}
.header-navbar__body {
  display: flex;
  align-items: center;
  gap: 15px;
  transition: all var(--animation-speed) ease;
}
@media (max-width: 1000px) {
  .header-navbar__body {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: calc(-1 * clamp(1.875rem, -1.134rem + 15.04vw, 12.5rem));
    top: -100%;
    width: 100vw;
    height: 100vh;
    opacity: 0;
    visibility: hidden;
    background-color: #5453C3;
    transition: all var(--animation-speed) ease;
    z-index: 100;
  }
  .header-navbar__body.active-navbar-menu {
    transition: all var(--animation-speed) ease;
    top: -20px;
    opacity: 1;
    visibility: visible;
  }
}
.header-navbar__item {
  font-size: 1.5rem;
  font-weight: 800;
  display: flex;
  align-items: center;
  gap: 15px;
  cursor: pointer;
}

.burger__menu {
  position: relative;
  z-index: 1000;
  display: none;
  width: 30px;
  height: 18px;
  cursor: pointer;
  transition: all var(--animation-speed) ease;
}
@media (max-width: 1000px) {
  .burger__menu {
    display: block;
  }
}
.burger__menu span {
  top: calc(50% - 1px);
  left: 0;
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #ffffff;
  transition: all var(--animation-speed) ease;
}
.burger__menu span:first-child {
  top: 0px;
}
.burger__menu span:last-child {
  top: auto;
  bottom: 0px;
}
.burger__menu.active-burger span {
  transform: scale(0);
}
.burger__menu.active-burger span:first-child {
  transform: rotate(135deg);
  top: calc(50% - 1px);
}
.burger__menu.active-burger span:last-child {
  transform: rotate(45deg);
  bottom: calc(50% - 1px);
}