.servers {
  margin: 40px 0px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.servers__title {
  font-size: clamp(1.75rem, 1.568rem + 0.91vw, 2.25rem);
}
.servers__container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 30px;
}
@media (max-width: 1002px) {
  .servers__container {
    flex-direction: column;
  }
}

.block-servers__item {
  position: relative;
  padding: 20px;
  background-color: transparent;
  border: 2px solid #27272D;
  border-radius: 20px;
  max-width: 280px;
  width: 100%;
  min-height: 390px;
  max-height: 390px;
  height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: column;
  gap: 20px;
  transition: all var(--animation-speed) ease;
}
.block-servers__item:hover {
  background-color: #27272D;
}
.block-servers__item:hover .block-servers__button {
  background-color: #5453C3;
}
.block-servers__item p {
  color: rgb(143, 143, 148);
  max-height: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.block-servers__item h1 {
  display: flex;
  align-items: center;
}
.block-servers__item h1 img {
  width: 32px;
  margin-right: 15px;
}
.block-servers__item h4 {
  margin-top: auto;
}
.block-servers__button {
  cursor: pointer;
  width: 100%;
  padding: 15px;
  text-align: center;
  border-radius: 15px;
  font-size: 1.2rem;
  background-color: #27272D;
  transition: all var(--animation-speed) ease;
}
.block-servers__button::-moz-selection {
  background-color: transparent;
}
.block-servers__button::selection {
  background-color: transparent;
}