.events__container {
  width: 100%;
  height: 100%;
  margin-top: 2rem;
  margin-bottom: 5rem;
}

.event__block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 3rem;
}
@media (max-width: 1400px) {
  .event__block {
    flex-direction: column;
  }
}
.event__timer {
  position: relative;
  font-size: clamp(4.5rem, 3.818rem + 2.91vw, 6rem);
  text-align: center;
  margin-top: 1.5rem;
}

.eventBlock__right {
  width: 600px;
}
@media (max-width: 800px) {
  .eventBlock__right {
    width: clamp(18.75rem, 2.206rem + 70.59vw, 37.5rem);
  }
}

.left-general {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1.5rem;
}
.left-general__title {
  font-size: 3rem;
}
.left-general__title::first-letter {
  color: #8D397B;
  font-size: 3.7rem;
}
.left-general__text {
  font-size: 1.3rem;
}