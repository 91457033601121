.gallery__container {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 150px;
}
.gallery__switch {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-bottom: 50px;
}
.gallery__switch img {
  cursor: pointer;
}
.gallery__title {
  display: flex;
  align-items: center;
  font-size: 2rem;
  z-index: 10;
  text-align: center;
  justify-content: center;
}
.gallery__text {
  display: flex;
  justify-content: center;
  font-size: 2rem;
  margin-top: 30px;
  text-align: center;
}
.gallery__body {
  width: clamp(18.75rem, 1.873rem + 72.01vw, 71rem);
  height: 100%;
}
.gallery__img {
  width: 100%;
}