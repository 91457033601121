.hero {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
}
.hero__main-block {
  width: 600px;
}
.hero__decor-pic {
  display: block;
  position: relative;
  right: -150px;
  overflow-x: hidden;
}
@media (max-width: 1200px) {
  .hero__decor-pic {
    position: absolute;
    left: calc(140px + clamp(0rem, -9.926rem + 42.35vw, 11.25rem));
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
  }
}
@media (max-width: 1200px) and (max-width: 361px) {
  .hero__decor-pic {
    display: none;
  }
}
@media (max-width: 1200px) {
  .hero__decor-pic::before {
    display: none;
    width: 0px;
    height: 0px;
  }
}
.hero__decor-pic img {
  width: 600px;
  z-index: 1;
}
@media (max-width: 1200px) {
  .hero__decor-pic img {
    width: clamp(11.25rem, 6.25rem + 25vw, 25rem);
  }
}
.hero__decor-pic::before {
  content: "";
  position: absolute;
  z-index: -1;
  background-color: #27272D;
  border-radius: 30px;
  width: 100%;
  height: 90%;
  left: 110px;
  top: 43px;
}

.block {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
}
.block__title {
  font-size: 3.75rem;
  font-weight: 800;
  line-height: 1;
}
.block__text {
  color: #77777C;
  font-size: 1rem;
  font-weight: 800;
  line-height: 1.4;
}
.block__buttons {
  display: flex;
  align-items: center;
  gap: 25px;
}

.buttons__servers {
  cursor: pointer;
  padding: 20px clamp(1.875rem, 1.344rem + 2.65vw, 3.75rem);
  background-color: #5453C3;
  border-radius: 15px;
  font-size: 1.2rem;
  transition: all var(--animation-speed) ease;
}
.buttons__servers:hover {
  opacity: 0.75;
}
.buttons__servers::-moz-selection {
  background-color: transparent;
}
.buttons__servers::selection {
  background-color: transparent;
}
.buttons__copy-ip {
  position: relative;
  cursor: pointer;
  padding: 20px clamp(1.875rem, 1.344rem + 2.65vw, 3.75rem);
  background-color: transparent;
  border: 2px solid #27272D;
  border-radius: 15px;
  font-size: 1.2rem;
  transition: all var(--animation-speed) ease;
}
.buttons__copy-ip:hover {
  background-color: #27272D;
  opacity: 0.75;
}
.buttons__copy-ip::-moz-selection {
  background-color: transparent;
}
.buttons__copy-ip::selection {
  background-color: transparent;
}