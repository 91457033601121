:root {
  --animation-speed: 0.5s;
}

.mods__container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 15px;
}

.container-element {
  min-height: 600px;
}
.container-element__title {
  font-size: clamp(1.75rem, 1.568rem + 0.91vw, 2.25rem);
}
.container-element__text {
  font-size: 1rem;
  font-weight: 800;
}
.container-element__block {
  width: 100%;
}
.container-element__text-search {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
}
.container-element__text-search div {
  display: flex;
  align-items: center;
  gap: 10px;
}
@media (max-width: 900px) {
  .container-element__text-search {
    flex-direction: column;
    gap: 30px;
    align-items: flex-start;
  }
}
.container-element__search {
  position: relative;
  width: 280px;
  height: 30px;
  outline: none;
  color: #FFFFFF;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid #27272D;
  font-size: 1.4rem;
  font-weight: 700;
  padding: 0px 0px 8px 8px;
  transition: all var(--animation-speed) ease;
}
.container-element__search:focus {
  border-bottom: 2px solid #5453C3;
}

.mods-block {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  gap: 15px;
}
.mods-block__item {
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 20px;
  background-color: transparent;
  border: 2px solid #27272D;
  border-radius: 20px;
  width: 280px;
  min-width: 280px;
  height: 420px;
  gap: 10px;
  margin-bottom: 30px;
  transition: all var(--animation-speed) ease;
}
.mods-block__item img {
  display: block;
  margin: 0 auto;
  margin-bottom: 10px;
}
.mods-block__item a {
  cursor: pointer;
  width: 100%;
  padding: 15px;
  text-align: center;
  border-radius: 15px;
  font-size: 1.2rem;
  background-color: #27272D;
  margin-top: auto;
  transition: all var(--animation-speed) ease;
}
.mods-block__item a::-moz-selection {
  background-color: transparent;
}
.mods-block__item a::selection {
  background-color: transparent;
}
.mods-block__item p {
  text-overflow: clip;
  line-height: 1.2;
  max-height: 100px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.mods-block__item:hover {
  background-color: #27272D;
}
.mods-block__item:hover a {
  background-color: #5453C3;
}